#root {
	display: flex;
	width: 100%;
	height: 100%;
	flex: 1;
}

.app-content {
	width: 100vw;
}

a:hover {
	color: inherit;
}
