.full-width {
  width: 100%;
}

.centralize-vertical {
  height: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.fr-100-horizontal {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

.p-absolute {
  position: absolute;
}

.item-with-hover {
  cursor: pointer;
}

.margin-0 {
  margin: 0px !important;
}

.padding-0 {
  padding: 0px !important;
}

#path {
  position: relative;

  input {
    padding-left: 180px;
  }

  label,
  input {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  label::after {
    content: ''attr(data-domain);
    position: absolute;
    top: 48px;
    left: 10px;

    z-index: 999;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #a1a1a1;
  }
}

.switch-disabled {
  opacity: 0.5;
}

.modal-open .modal {
  overflow: hidden !important;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: 90vh;
  width: 50vw;
  min-width: 305px;
  max-width: 750px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}